import dcmjs from 'dcmjs';
import dicomImageLoader from '@cornerstonejs/dicom-image-loader';
import FileLoader from './fileLoader';

const DICOMFileLoader = new (class extends FileLoader {
  fileType = 'application/dicom';
  loadFile(file, imageId) {
    return dicomImageLoader.wadouri.loadFileRequest(imageId);
  }

  getDataset(image, imageId) {
console.log(`START dcmjs.data.DicomMessage.readFile ${image}`);
    const dicomData = dcmjs.data.DicomMessage.readFile(image);
console.log("FINISH dcmjs.data.DicomMessage.readFile");
    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict);
console.log("FINISH dcmjs.data.DicomMetaDictionary.naturalizeDataset");
    dataset.url = imageId;
console.log("FINISH imageId");
    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomData.meta);

console.log("FINISH dcmjs.data.DicomMetaDictionary.namifyDataset");
    dataset.AvailableTransferSyntaxUID =
      dataset.AvailableTransferSyntaxUID || dataset._meta.TransferSyntaxUID?.Value?.[0];

console.log("FINISH dataset.AvailableTransferSyntaxUID");

    return dataset;
  }
})();

export default DICOMFileLoader;
