import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { DicomMetadataStore, MODULE_TYPES } from "@ohif/core";
import { useSearchParams } from "react-router-dom";

import Dropzone from "react-dropzone";
import filesToStudies from "./filesToStudies";

import { extensionManager } from "../../App.tsx";

import { Icon, Button, LoadingIndicatorProgress } from "@ohif/ui";

const getLoadButton = (onDrop, text, isDir) => {
  return (
    <Dropzone onDrop={onDrop} noDrag>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <Button
            rounded="full"
            variant="contained" // outlined
            disabled={false}
            endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
            className={classnames("font-medium", "ml-2")}
            onClick={() => {}}
          >
            {text}
            {isDir ? (
              <input
                {...getInputProps()}
                webkitdirectory="true"
                mozdirectory="true"
              />
            ) : (
              <input {...getInputProps()} />
            )}
          </Button>
        </div>
      )}
    </Dropzone>
  );
};

type LocalProps = {
  modePath: string;
};

function Local({ modePath }: LocalProps) {
  const navigate = useNavigate();
  const dropzoneRef = useRef();
  const [dropInitiated, setDropInitiated] = React.useState(false);

  // Initializing the dicom local dataSource
  const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
  const localDataSources = dataSourceModules.reduce((acc, curr) => {
    const mods = [];
    curr.module.forEach((mod) => {
      if (mod.type === "localApi") {
        mods.push(mod);
      }
    });
    return acc.concat(mods);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const readFile = searchParams.get("readFile");

  const firstLocalDataSource = localDataSources[0];
  const dataSource = firstLocalDataSource.createDataSource({});

  const microscopyExtensionLoaded =
    extensionManager.registeredExtensionIds.includes(
      "@ohif/extension-dicom-microscopy"
    );

  const US1 = ["https://dicom.maventhoughts.com/files/dicom/US/1/KRRVQO00"];

  const US2 = ["https://dicom.maventhoughts.com/files/dicom/US/2/KRRVQMRK"];

  const US3 = ["https://dicom.maventhoughts.com/files/dicom/US/3/KRRVQN2O"];

  const US4 = ["https://dicom.maventhoughts.com/files/dicom/US/4/KRRVQQ5U"];

  const US5 = ["https://dicom.maventhoughts.com/files/dicom/US/5/KRRVQPUO"];

  const P1US1 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VR9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VRZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38VS8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W28",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W29",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W2Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W30",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W31",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W32",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W33",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W35",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W36",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W37",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W38",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W39",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-1/L4H38W3K",
  ];

  const P1US2 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DI8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DII",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DI9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DID",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-2/L4GK0DIT",
  ];

  const P1US3 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B21",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B28",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQ9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B22",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B29",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B23",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B2A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B24",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B25",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B26",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45AQL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B1T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B20",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45B27",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BCQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-3/L4H45BNN",
  ];

  const P1US4 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17G8UO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GJYO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GPIO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H4N4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HAI8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HGDC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HLM8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HQK1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HXNK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I44W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17IAB4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GBBK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GL74",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GW00",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H5KG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HBFK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HGDD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HM8G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HRHC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HYKW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I6AO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17IAB5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GDSG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GLTC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GZ34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H6HS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HBQO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HGDE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HO34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HSPS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HZ74",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I6AP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KYMFBJJK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GH6O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GLTD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H1K0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H740",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HCO0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HHWW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HOE8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HTY8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HZI8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I6WW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KYMFBQ0W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GIF4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GM4G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H2SG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H81C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HDLC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HHWX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HQ8W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HW40",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I11S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I7U8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GJCG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17GN1S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H3PS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17H8YO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HETS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HIU8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HQK0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17HWQ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I2LC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-4/KY17I92O",
  ];

  const P1US5 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WGSW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WHQ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WJKW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WQOG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WZMO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X3N4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X4VK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X5SW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X7NK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WH40",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WI1C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WKI8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WS80",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X08W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X3N5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X56O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X6Q8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X7NL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WH41",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WINK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WL4G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WVM8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X2EO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X4KG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X56P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X71C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X7NM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WHF4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WIYO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WMZ4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17WY34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X30W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X4KH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X56Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X7CG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-US-5/KY17X7NN",
  ];

  const P1IVUS = [
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-IVUS/KX4JSLB5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-IVUS/KX4JSX1C",
  ];

  const P1XA1 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R2ZK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3AQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3LU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R480",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R483",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R4J5",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R4UA",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R55E",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R5GH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3AO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3LS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3WW",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R481",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R484",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R4U8",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R55C",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R55F",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R5GI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3AP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3LT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R3WX",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R482",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R4J4",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R4U9",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R55D",
    // "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-1/KY16R5GG",
  ];

  const P1XA2 = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-2/L4H3UT9C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-2/L4H3UT9D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-XA-2/L4H3UT9E",
  ];

  const P1MRI = [
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEF8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYSI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3F6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7QT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHB6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLXV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LV74",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZTS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4RK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M939",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYSJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3F7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L81S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHB7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLXW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LV75",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZTT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4RL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9E8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQ9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYSK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3F8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L81T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCOG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHB8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LM8W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LV76",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZTU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4RM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9E9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZ3K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3Q8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L81U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCOH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHBA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LM8X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQVK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LV77",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZTV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4RN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9EA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZ3L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3Q9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L81V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCOI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHM8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LM8Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQVL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LV78",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZTW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9EB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZ3M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3QA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L81W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCOJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHM9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LM8Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQVM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVI8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M04W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9EC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZ3N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3QB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8CW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCZK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHMA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LM90",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQVN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVI9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M04X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9ED",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KEQE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZ3O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3QC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8CX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCZL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHMB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMK0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQVO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVIA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M04Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KF1C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZEO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3QD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8CY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCZM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHMC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMK1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVIB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M04Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KF1D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZEP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L41C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8CZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCZN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHXC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMK2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVIC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M050",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M52T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KUH1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZEQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L41D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8D0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCZO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHXD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMK3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVTC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5DS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KUS0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZER",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L41E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8O0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHXE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMK4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVTD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5DT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KUS1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZES",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L41F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8O1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHXF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMV4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVTE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5DU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M9PH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KUS3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZET",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L41G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8O2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHXG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMV5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LR6T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVTF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5DV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MA0G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KUS4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZPS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8O3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LI8G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMV6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRHS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LVTG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5OW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MA0H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KV34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZPT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8O4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LI8H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LMV7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRHT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0G5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5OX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MA0I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KV35",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZPU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8Z4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDAT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LI8I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LN68",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRHU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0R4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5OY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MA0J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KV36",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KZPV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8Z5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDLS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LI8J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LN69",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRHV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0R5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5OZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MA0K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KV37",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L00W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8Z6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDLT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LI8K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LN6A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRHW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0R6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5P0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KV38",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L00X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4CL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8Z7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDLU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIJK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LN6B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRSW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0R7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M5P1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVE8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L00Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4NK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L8Z8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDLV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIJL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LN6C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRSX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LW4L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M0R8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M600",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVE9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L00Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4NL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9A8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDLW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIJM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNHC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRSY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWFK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M128",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M601",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVEA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L010",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4NM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9A9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDWW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIJN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNHD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRSZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWFL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M129",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M602",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVEB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L011",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4NN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9AA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDWX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIJP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNHE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LRT0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWFM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M12A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M603",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MABP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVEC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0C0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4NO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9AB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDWY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIUO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNHF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS40",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWFN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M12B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M604",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVPC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0C1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4YO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9AC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDWZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIUP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNHG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS41",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWFO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M12C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6B4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVPD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0C2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4YP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LDX0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIUQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS42",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1DC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6B5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVPE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0C3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4YQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE80",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIUR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS43",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1DD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6B6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVPF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0C4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4YR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE81",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LIUS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS44",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1DE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6B7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KVPG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0N4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L4YS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE82",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJ5S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LS45",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1DF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6B8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAMT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KW0G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0N5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE83",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJ5T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSF4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1DG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6M8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAXS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KW0H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0N6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9LH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE84",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJ5U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LNSL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSF5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LWQT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6M9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAXT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KW0I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0N7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LE85",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJ5V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LO3K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSF6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LX1S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6MA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAXU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KW0K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0N8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEJ4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJGW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LO3L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSF7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LX1T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6MB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAXV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KW0L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0Y8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEJ5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJGX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LO3M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSF8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LX1U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6MC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MAXW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0Y9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L59X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEJ6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJGY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LO3N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSQ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LX1V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6MD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB8W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0YA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5KW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEJ7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJGZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LO3O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSQ9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LX1W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1OL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB8X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0YB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5KX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L9WL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEJ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJH0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOEO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSQA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXCW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1ZK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB8Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L0YC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5KY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LA7K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEU8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOEP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSQB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXCX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1ZL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB8Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5KZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LA7L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEU9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOEQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LSQC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXCY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1ZM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB90",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWBP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5L0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LA7M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEUA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOER",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXCZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1ZN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MB92",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWMO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LA7N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEUB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOES",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXD0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M1ZO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M6XH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWMP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LA7O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LEUC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOET",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXO0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M78G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWMQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LAIO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LF5C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LJS5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOPS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXO1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M78H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWMR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L19H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LAIP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LF5D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LK34",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOPT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXO2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M78I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWMS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1KG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LAIQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LF5E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LK35",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOPU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LT1H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXO3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M78J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWXS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1KH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L5W5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LAIR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LF5F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LK36",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOPV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXO4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M78K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBK5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWXT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1KI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L674",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LAIS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LF5G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LK37",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LOPW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXZ4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2AT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7JK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBV4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWXU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1KJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L675",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LATS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFGG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LK38",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LP0W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXZ5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2LS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7JL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBV5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWXV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L676",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LATT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFGH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKE8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LP0X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXZ6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2LT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7JM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBV6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KWXW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L677",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LATU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFGI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKE9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LP0Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXZ7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2LU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7JN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBV7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KX8W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L678",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LATV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFGJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKEA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LP0Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTCL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LXZ8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2LV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7JO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MBV8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KX8X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6I8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LATW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFGK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKEB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LP10",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYA8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2WW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7UO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC68",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KX8Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6I9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LB4W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFRK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKEC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYA9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2WX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7UP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC69",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KX90",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L1VP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6IA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LB4X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFRL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKPC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYAA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M2WY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7UQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC6A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KX91",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L26O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6IB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LB4Y",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFRM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKPD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYAB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M380",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7UR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC6B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXK0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L26P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6IC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LB4Z",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFRN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKPE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYAC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M381",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M7US",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC6C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXK1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L26Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6ID",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LB50",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LFRO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKPF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTNP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYLC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M382",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MC6D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXK2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L26R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6TC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LG2O",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LKPG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPC5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTYO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYLD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M383",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCHC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXK3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L26S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6TD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LG2P",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPN4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTYQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYLE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M384",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCHD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXK4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2HS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6TE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LG2Q",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPN5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTYR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYLF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCHE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXV4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2HT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6TF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LG2R",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPN6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTYS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYLG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCHF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXV5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2HU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L6TG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGDS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPN7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LTYT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M85X",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCHG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXV6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2HV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L74G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBG5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGDT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPN8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LU9S",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8GW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCSG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXV7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2HW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L74H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBR4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGDU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LL0L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPY8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LU9T",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8GX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCSH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KXV8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2SW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L74I",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBR5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGDV",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLBK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPY9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LU9U",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3J9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8GY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCSI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY68",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2SX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L74J",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBR6",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGDW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLBL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPYA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LU9V",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3U8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8GZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCSJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY69",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2SY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L74K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBR7",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGOW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLBM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPYB",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LU9W",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LYWL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3U9",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8H0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MCSK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY6A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2SZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LBR8",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGOX",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLBN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LPYC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUKW",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZ7K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M3UA",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8S0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MD3K",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY6B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2T0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LC28",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGOY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLBO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUKY",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZ7L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M45C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8S1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MD3L",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY6C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L2T1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LC29",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LGOZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLMO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUKZ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZ7M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M45D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8S2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MD3M",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KY6D",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L340",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FN",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LC2A",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LH00",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLMP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUL0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZ7N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M45E",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8S3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MD3N",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYHC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L341",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LC2B",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LH01",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLMQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9F",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW0",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M8S4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96MDEO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYHD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L342",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7FP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LC2C",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LH02",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLMR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9G",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW1",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M934",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYHE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L343",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7QO",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDC",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LH03",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLMS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQ9H",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW2",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GJ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M935",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYHF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L344",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7QP",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDD",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LH04",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLXS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW3",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIR",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GK",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M936",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYSG",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3F4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7QQ",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDE",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHB4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLXT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW4",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GL",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M937",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96KYSH",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L3F5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96L7QS",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LCDF",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LHB5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LLXU",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LQKI",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LUW5",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96LZIT",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M4GM",
    "https://dicom.maventhoughts.com/files/dicom/P1/P1-MRI/KM96M938",
  ];

  const P2MRI = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7B6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7ET",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7B8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7ED",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7B9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7H0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7H1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7H2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7H3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7E9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7ER",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7F9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7BN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7C9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7CR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7D9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7DQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7EB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7ES",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7FR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7G9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-MRI/6RK8M7GR",
  ];

  const P2XA1 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4K0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4V4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4V5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4V6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4V7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U4V8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U568",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U569",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-1/KY16U56A",
  ];

  const P2XA2 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416WA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416WC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416W9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-XA-2/L4H416WB",
  ];

  const P2IVUS = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-IVUS/9AZTNTFZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-IVUS/9AZTNTG5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-IVUS/9AZTNTG7",
  ];

  const P2US1 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2V",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE39",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3N",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE41",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4F",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3A",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE42",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2X",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3B",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3P",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE43",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2Y",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3C",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3Q",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE44",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2Z",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3D",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3R",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE45",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE30",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3E",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE46",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE31",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3F",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3T",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE47",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE32",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3U",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE48",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2P",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE33",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3H",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3V",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE49",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADST",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2Q",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE34",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3I",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4A",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2R",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE35",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3J",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3X",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4B",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADT9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE36",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3Y",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4C",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2T",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE37",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3L",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3Z",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4D",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADGT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADRR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADS5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADSX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3ADTB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE2U",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE38",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE3M",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE40",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-1/L4H3AE4E",
  ];

  const P2US2 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55C",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5H1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55D",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55H",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55L",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55P",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55T",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55E",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55I",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55M",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55Q",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55U",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55F",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55J",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55N",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R55R",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5GW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-2/L4H4R5H0",
  ];
  const P2US3 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HL0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ1",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJH",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJX",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ2",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJA",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJI",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJY",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK6",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKE",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HIV",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJ3",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJB",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJJ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HJZ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HK7",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKF",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-3/L4GK0HKV",
  ];
  const P2US4 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OIDS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17ON0G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P0LC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P7DS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PCBK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PESG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PLW0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PUU8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QAW0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QC4H",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCQP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OJM8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17ONXS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P1IO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P7DT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PCBL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PFPS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17POZ4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PZ5S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QB74",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QC4I",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCQQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OK8G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OOV4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P328",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P7DU",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PCBM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PGY8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PQIO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17Q28W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QB75",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QC4J",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFQ",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCQR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OKUO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OQ3K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P580",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P7OW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PCBN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PGY9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PQIP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17Q4PS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QBI8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFR",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCQS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OL5S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OTHS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P65C",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P8M8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PD8W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PHVK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PQTS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17Q840",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QBI9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OLS0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OW9S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P6GG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P8XC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PEHC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PI6O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PRG0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17Q91C",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QBTC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OME8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17OXI8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17P72O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PA5S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PEHD",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PJ40",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17PSDC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17Q9YO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QC4G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCFN",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-4/KY17QCQO",
  ];
  const P2US5 = [
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18024G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1805TS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180C00",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180XLS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181B6O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181J7K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181P2P",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181UXS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181YY9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1824I8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182DGG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182KK0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1802QO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180728",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180CXC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1814PC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181CQ8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181KG0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181QXC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181VV4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1821F4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1824TC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182EDS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182LSG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1802QP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1807OG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180E5S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18183K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181E9S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181LZK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181RUO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181WHC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1821F5",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18261S",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182FB4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182N0W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1803CW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1808WW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180FPC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18190W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181F74",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181ORK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181SS0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181XPS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1821Q8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18294W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182G8G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182NY8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1803Z4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1809U8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180GXS",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1819Y8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181FTC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181ORL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181TPC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181XPT",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1822CG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182ADC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182HGW",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182OKG",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1804LC",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY1809U9",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180M6O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181AVK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181GQO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181ORM",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181UMO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181Y0W",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182474",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182BAO",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182I34",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182Q40",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY18057K",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180ARK",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY180R4G",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181AVL",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181HO0",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181P2O",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181UMP",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY181YY8",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182475",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182CJ4",
    "https://dicom.maventhoughts.com/files/dicom/P2/P2-US-5/KY182J0G",
  ];

  const onDrop = async (acceptedFiles) => {
    const studies = await filesToStudies(acceptedFiles, dataSource);

    const query = new URLSearchParams();

    if (microscopyExtensionLoaded) {
      // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
      //     we should make the local drag and drop navigate to the worklist and
      //     there user can select microscopy mode
      const smStudies = studies.filter((id) => {
        const study = DicomMetadataStore.getStudy(id);
        return (
          study.series.findIndex(
            (s) => s.Modality === "SM" || s.instances[0].Modality === "SM"
          ) >= 0
        );
      });

      if (smStudies.length > 0) {
        smStudies.forEach((id) => query.append("StudyInstanceUIDs", id));

        modePath = "microscopy";
      }
    }

    // Todo: navigate to work list and let user select a mode
    studies.forEach((id) => query.append("StudyInstanceUIDs", id));
    query.append("datasources", "dicomlocal");

    navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
  };

  useEffect(async () => {
    document.body.classList.add("bg-black");

    console.log("TESTING LOCAL!!!");

    let fileList: string[] = [];
    if (readFile) {
      if (readFile === "P1US1") {
        fileList = P1US1;
      } else if (readFile === "P1US2") {
        fileList = P1US2;
      } else if (readFile === "P1US3") {
        fileList = P1US3;
      } else if (readFile === "P1US4") {
        fileList = P1US4;
      } else if (readFile === "P1US5") {
        fileList = P1US5;
      } else if (readFile === "P1IVUS") {
        fileList = P1IVUS;
      } else if (readFile === "P1XA1") {
        fileList = P1XA1;
      } else if (readFile === "P1XA2") {
        fileList = P1XA2;
      } else if (readFile === "P1MRI") {
        fileList = P1MRI;
      } else if (readFile === "P2US1") {
        fileList = P2US1;
      } else if (readFile === "P2US2") {
        fileList = P2US2;
      } else if (readFile === "P2US3") {
        fileList = P2US3;
      } else if (readFile === "P2US4") {
        fileList = P2US4;
      } else if (readFile === "P2US5") {
        fileList = P2US5;
      } else if (readFile === "P1US5") {
        fileList = P1US5;
      } else if (readFile === "P2IVUS") {
        fileList = P2IVUS;
      } else if (readFile === "P2XA1") {
        fileList = P2XA1;
      } else if (readFile === "P2XA2") {
        fileList = P2XA2;
      } else if (readFile === "P2MRI") {
        fileList = P2MRI;
      } else if (readFile === "US1") {
        fileList = US1;
      } else if (readFile === "US2") {
        fileList = US2;
      } else if (readFile === "US3") {
        fileList = US3;
      } else if (readFile === "US4") {
        fileList = US4;
      } else if (readFile === "US5") {
        fileList = US5;
      }
    }
    console.log(fileList);

    let downloadFile: any[] = [];

    for (let file of fileList) {
      await fetch(`${file}`).then(async (response) => {
        await response.arrayBuffer().then((result) => {
          const file = new File([result], "test.dcm", {
            type: "application/dicom",
          });
          downloadFile.push(file);
          console.log(file);
        });
      });
    }

    onDrop(downloadFile);

    return () => {
      document.body.classList.remove("bg-black");
    };
  }, [readFile]);

  // Set body style
  useEffect(() => {
    document.body.classList.add("bg-black");
    return () => {
      document.body.classList.remove("bg-black");
    };
  }, []);

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={(acceptedFiles) => {
        setDropInitiated(true);
        onDrop(acceptedFiles);
      }}
      noClick
    >
      {({ getRootProps }) => (
        <div {...getRootProps()} style={{ width: "100%", height: "100%" }}>
          <div className="flex h-screen w-screen items-center justify-center ">
            <div className="bg-secondary-dark mx-auto space-y-2 rounded-lg py-8 px-8 drop-shadow-md">
              <div className="flex items-center justify-center">
                <Icon name="logo-dark-background" className="h-28" />
              </div>
              <div className="space-y-2 pt-4 text-center">
                {dropInitiated ? (
                  <div className="flex flex-col items-center justify-center pt-48">
                    <LoadingIndicatorProgress
                      className={"h-full w-full bg-black"}
                    />
                  </div>
                ) : (
                  <div className="space-y-2">
                    <p className="text-base text-blue-300">
                      Note: You data is not uploaded to any server, it will stay
                      in your local browser application
                    </p>
                    <p className="text-xg text-primary-active pt-6 font-semibold">
                      Drag and Drop DICOM files here to load them in the Viewer
                    </p>
                    <p className="text-lg text-blue-300">Or click to </p>
                  </div>
                )}
              </div>
              <div className="flex justify-around pt-4 ">
                {getLoadButton(onDrop, "Load files", false)}
                {getLoadButton(onDrop, "Load folders", true)}
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

export default Local;
