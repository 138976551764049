import FileLoaderService from './fileLoaderService';
import { DicomMetadataStore } from '@ohif/core';

const processFile = async file => {
  try {
    const fileLoaderService = new FileLoaderService(file);
    console.log("FINISH fileLoaderService")
    const imageId = fileLoaderService.addFile(file);
    console.log("FINISH fileLoaderService.addFile")
    const image = await fileLoaderService.loadFile(file, imageId);
 console.log("FINISH fileLoaderService.loadFile")
    const dicomJSONDataset = await fileLoaderService.getDataset(image, imageId);
console.log("FINISH fileLoaderService.getDataset")
    DicomMetadataStore.addInstance(dicomJSONDataset);

console.log("FINISH DicomMetadataStore.addInstance")
  } catch (error) {
    console.log(error.name, ':Error when trying to load and process local files:', error.message);
  }
};

export default async function filesToStudies(files) {
  const processFilesPromises = files.map(processFile);
  await Promise.all(processFilesPromises);

  return DicomMetadataStore.getStudyInstanceUIDs();
}
